import { useGetCategoryResQuery } from '@/redux/features/api'
import { CategoryUnits, CategoryUnitsSchema } from '@/types'
import localforage from 'localforage'
import { useEffect, useState } from 'react'

/**
 * @returns everything from useGetCategoryResQuery, but with offline categoryUnits support
 */
export default function useActiveQuery() {
    const url = `/api/main/category`
    const swrRes = useGetCategoryResQuery()

    const [offlineCategories, setOfflineCategories] = useState<CategoryUnits | undefined>(undefined)

    useEffect(() => {
        localforage.getItem('categories').then((categories) => {
            if (CategoryUnitsSchema.safeParse(categories).success) {
                setOfflineCategories(categories as CategoryUnits)
            }
        })
    }, [])

    return { ...swrRes, categoryUnits: swrRes.data?.categoryUnits || offlineCategories, url }
}
